import { useEffect } from 'react'

const Irksome = () => {
  useEffect(() => {
    const elements: HTMLElement[] = Array.from(
      document.querySelectorAll(
        '.irk-me, .navbar a, nav a, footer p, h1, h2, h3, h4, h5, h6'
      )
    )
    elements.forEach(el => {
      const rotate = `rotate(${Math.floor(Math.random() * 10) - 5}deg)`
      const translate = `translate3d(${
        Math.floor(Math.random() * 40) - 20
      }px, ${Math.floor(Math.random() * 40) - 20}px, 0)`
      el.classList.add('irked')
      el.style.transform = `${rotate} ${translate}`
    })
  }, [])
  return null
}

export default Irksome
